/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
/* App imports */
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Utils from '../../utils'
import style from './index.module.less'
import config from '../../../gatsby-config'
import books from '../../../content/books/books.json'

export const aboutPropTypes = {
  data: PropTypes.shape({
    profilePhoto: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    flagIt: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.object.isRequired,
      }),
    }),
    flagEn: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fixed: PropTypes.object.isRequired,
      }),
    }),
    skillIcons: PropTypes.object.isRequired,
    bookIcons: PropTypes.object.isRequired,
  }),
}

class About extends React.Component {
  static propTypes = aboutPropTypes

  render() {
    let { profilePhoto, skillIcons, bookIcons } = this.props.data
    return (
      <Layout>
        <SEO
          title="Sven Sieverding"
          description="Software Developer"
          path="about"
          contentType="article"
        />
        <div className={style.container}>
          <div className={style.photo}>
            <Img fluid={profilePhoto.childImageSharp.fluid} backgroundColor={config.backgroundColor} alt="Sven Sieverding" />
          </div>
          <div className={style.content}>
            <h1>Hallo, ich bin Sven!</h1>
            <h2>Software Developer</h2>
            <p>
              Mein Lieblingszitat ist "<cite>When all you have is a Hammer, everything looks like a Nail</cite>" (Abraham Maslow)
              </p>
            <p>
              Warum? Weil die Welt gerade im Zeitalter von Cloud und KI nicht so einfach ist und ein einzelnes Tool oder eine einzelne Sichtweise in der Regel nicht genügt, um komplexe Probleme zu lösen. Lebenslanges Lernen halte ich daher für unglaublich wichtig.
              </p>
            <p>
              Ein weiterer Grund ist, dass man vielleicht nicht jedes Mal das Rad komplett neu erfinden muss. Durch Wiederverwendung von Komponenten und Plattformen gelangt man schneller zum Ziel... Aber nur, wenn man sie auch kennt.
            </p>
            <br />
            <h4>Damit entwickele ich</h4>
            <ImageList edges={skillIcons.edges} />
            <h4>Ein paar Bücher, die ich in den letzten Zeit gelesen habe:</h4>
            <BookList edges={bookIcons.edges} />
          </div>
        </div>
      </Layout>
    )
  }
}


export const bookListPropTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
}

class BookList extends React.Component {
  static propTypes = bookListPropTypes

  render = () => (
    <div className={style.iconsContainer}>
      {books.map((book) => (
        this.props.edges.filter((e) => e.node.name === book.id).map(({ node: { name, childImageSharp } }) => (
          <div className={style.iconWrapper} key={book.id}>
            <a href={book.link} target="_blank" rel="noreferrer" aria-label={book.title} alt={book.title}>
              <Img
                fixed={childImageSharp.fixed}
                alt={book.id + ' logo'}
                title={book.title}
              />
            </a>
          </div>
        ))
      ))}
    </div>
  )
}







export const imageListPropTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
}

class ImageList extends React.Component {
  static propTypes = imageListPropTypes

  render = () => (
    <div className={style.iconsContainer}>
      {this.props.edges
        .sort((edgeA, edgeB) =>
          edgeA.node.name.toLowerCase() > edgeB.node.name.toLowerCase() ? 1 : -1
        )
        .map(({ node: { name, childImageSharp } }) => (
          <div className={style.iconWrapper} key={name}>
            <Img
              fixed={childImageSharp.fixed}
              alt={name + ' logo'}
              title={name}
            />
            <label>
              {iconsNameMap[name] ? iconsNameMap[name] : Utils.capitalize(name)}
            </label>
          </div>
        ))}
    </div>
  )
}

export const query = graphql`
  {
    profilePhoto: file(name: { eq: "profile-photo" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    skillIcons: allFile(filter: { dir: { regex: "/about/skills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }   
    bookIcons: allFile(filter: { dir: { regex: "/content/books$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(height: 250) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`
// Use to set specific icons names
export const iconsNameMap = {
  css: 'CSS',
  html: 'HTML',
  jquery: 'JQuery',
  nodejs: 'Node.js',
  vuejs: 'Vue.js',
  gruntjs: 'Grunt.js',
}

export default About
